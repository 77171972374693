<template>
  <div>
    <!-- Start Breadcrump Area  -->
    <div
      class="rn-page-title-area ptb--60 bg_image bg_image--27"
      data-black-overlay="7"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center blog-single-page-title pt--100">
              <h1 class="heading-title theme-gradient">
                Qui sommes nous?
              </h1>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- Start Blog Details Area  -->
    <div class="rn-blog-details pt--60 pb--60 bg_color--1">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="inner-wrapper">
              <div class="inner">
                <h2>Si vous êtes sur cette page ...</h2>
                <p>
                  C'est que vous voulez découvrir
                  <em
                    >qui nous sommes, ce que l'on fait et pourquoi on le fait </em
                  >.
                  <br />
                  Nous <span class="theme-gradient">ShirkaLAB</span>, sommes une
                  agence de création d'applications vocales sur Google Assistant
                  & Alexa. Une application vocale permet de créer des services
                  sur ces enceintes connectées au même titre que les
                  applications sur les téléphones portables. En France près de
                  20% des foyers sont déjà équipés avec une vraie appétence chez
                  les séniors.
                </p>
                <div class="thumbnail">
                  <img
                    src="../assets/images/blog/mains.jpg"
                    alt="Blog Images"
                  />
                </div>
                <p class="mt--40">
                  Après avoir travaillé avec l'ARS sur un projet pilote (OUNA)
                  d’assistant vocale dans les EHPAD et les résidences séniors,
                  nous sommes persuadés que ces nouveaux services peuvent
                  contribuer à réduire la fracture numérique chez nos aînés. Ils
                  apportent par leur facilité d'utilisation (il suffit juste de
                  parler) d’excellentes réponses à bien des situations créées
                  par la nécessaire distanciation sociale et sanitaire.
                </p>
                <p>
                  Ces services vont redonner une grande autonomie aux seniors
                  grâce aux capacités de l'intelligence artificielle des
                  assistants vocaux.
                </p>
                <p>
                  La téléassistance est une des solutions favorisant le maintien
                  à domicile des séniors, pourtant 90% des +75 ans n'utilisent
                  pas ce type de service, jugé peu efficace et stigmatisant.
                  Notre nouveau service simple d’utilisation (aucun matériel
                  spécifique n’est requis) permet de faciliter l’adoption des
                  services de téléassistance.
                </p>
                <blockquote class="rn-blog-quote">
                  C'est dans ce contexte que nous avons créé le service
                  <em> Mes amis de confiance</em>, le premier service d'alerting
                  fondé sur l’entraide disponible sur les assistants vocaux
                  Google Home et Alexa.
                </blockquote>

                <div
                  class="row mt-4"
                  style=" display: flex;justify-content: center;}"
                >
                  <div class="col-sm-12 col-md-4">
                    <div class="thumbnail">
                      <img
                        class="w-100"
                        src="../assets/images/blog/femmelecture.jpg"
                        alt=" lexa demande à mes amis de confiance de l'assistance"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <div class="content">
                      <h4 class="heading-title">
                        Nos missions
                      </h4>
                      <ul class="list-style mission">
                        <li>
                          Être l’allié bienveillant du mieux-vivre et de
                          l'autonomie.
                        </li>
                        <li>
                          Faciliter l’adoption des services de téléassistance
                        </li>
                        <li>Rendre l'assistance accessible à tous.</li>
                        <li>Réduire la fracture technologique des séniors.</li>
                        <li>
                          Proposer solutions d'entraides simples, pratique,
                          innovantes et efficaces.
                        </li>
                        <li>Sécuriser votre proche fragilisé.</li>
                        <li>Vivre avec sérénité.</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <p class="mt-15">
                  Convaincu que toute innovation technologique n’a de sens que
                  si elle est pensée en service,
                  <em>Mes amis de confiance</em> propose un service innovant
                  pour faciliter le quotidien des personnes dépendantes.
                </p>
                <p>
                  Ce service permet d’envoyer des notifications pour alerter vos
                  proches quand vous avez besoin d’assistance simplement en
                  utilisant votre voix. Lorsque vous demandez de l’assistance,
                  nous passons un appel vocal, envoyons un SMS et un e-mail à
                  chacun des contacts pré-renseigné sur notre plateforme lors de
                  l'inscription. Pour demander de l'assistance rien de plus
                  simple il suffit juste de dire : <br />
                  <em
                    >"Hey Google, demande à mes amis de confiance de
                    l'assistance"
                  </em>
                  si vous êtes équipé d'un assistant Google <br />ou
                  <em
                    >"Alexa, demande à mes amis de confiance de contacter mes
                    proches"
                  </em>
                  si vous possédez une enceinte Alexa (echo).
                </p>
                <p class="mt--25 mt_sm--5"></p>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Blog Details Area  -->
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      breadcrumbs: [
        {
          text: "Home",
          to: "/",
          disabled: false
        },
        {
          text: "About",
          to: "",
          disabled: true
        }
      ]
    };
  }
};
</script>

<style>
.mission {
  font-size: initial;
  padding-left: 1em !important;
}
</style>
